@import url("/public/css/fontawesome-bedloop/css/all.css");
@import "~bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,700;1,100;1,200;1,700&display=swap");

@import "~react-image-gallery/styles/css/image-gallery.css";

:root {
    --corporatiu: #ff385c;
    --mida: 10px;
    --fons: #fbfbfd;
}

body {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    background-color: var(--fons);
}

a {
    color: var(--corporatiu);
}

figure {
    margin: 0 !important;
}

ul {
    list-style: none;
}

button {
    background: var(--corporatiu);
}

a {
    text-decoration: none;
    color: #000;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
    line-height: 2;
}

figcaption {
    position: absolute;
    margin: auto;
    top: 25em;
    bottom: 0;
    left: 0;
    right: 0;
}

.bg-body {
    --bs-bg-opacity: 1;
    background-color: var(--fons);
}

.color-corporatiu {
    color: var(--corporatiu);
}

.ml-5 {
    margin-left: 5px;
}

.ml-40 {
    margin-left: 40px;
}

.fons {
    background-color: var(--fons);
}

.menu {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
}

.navegacio {
    padding-left: 15px;
    padding-right: 15px;
    width: max-content;
}

.logo {
    max-height: 60px;
    margin-left: 20px;
    width: auto;
}

.no-padding {
    padding: 0;
}

.top-propietats {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    gap: 25px;
    padding-top: 40px;
}

.btn-primary {
    color: #fff;
    background-color: var(--corporatiu);
    border-color: var(--corporatiu);
}

.btn-primary:hover {
    color: #fff;
    background-color: var(--corporatiu);
    border-color: var(--corporatiu);
}

.menu {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
}

.navegacio {
    padding-left: 15px;
    padding-right: 15px;
}

.logo {
    max-height: 60px;
    margin-left: 20px;
    width: auto;
}

.logo-footer {
    max-height: 60px;
    margin-left: 20px;
    max-width: 80%;
}

.destacats {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    align-items: center;
    gap: 50px;
    padding: 50px;
}

.destacats-propietat {
    order: 1;
    background-color: #fff;
    border: none;
    border-radius: 15px;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
    height: 400px;
    width: 300px;
}

.destacats-imatge-propietat {
    width: 300px;
    border-radius: 15px 15px 0px 0px;
    /* max-height: 200px;*/
    height: 200px;
}
.destacats-text {
    padding: 20px;
    text-align: center;
}

.destacats-titol-propietat {
    font-size: 16px;
    display: block;
    margin-top: 10px;
    font-weight: 400;
}

.destacats-ciutat-propietat {
    display: block;
    font-size: 14px;
    margin-top: 1px;
    margin-bottom: 25px;
    color: #a2a2a2;
}

.destacats-icones-propietat {
    color: var(--corporatiu);
    display: block;
}

.destacats-noticies {
    order: 1;
    background-color: #fff;
    border: none;
    border-radius: 15px;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
    height: auto;
    width: 300px;
    cursor: pointer;
    max-height: 700px;
}

.destacats-text-noticies {
    padding: 10px;
    text-align: left;
    margin-bottom: 10px;
}

.destacats-imatge-noticies {
    width: 300px;
    border-radius: 15px 15px 0px 0px;
    max-height: 200px;
}

.destacats-titol-noticies {
    font-size: 18px;
    display: block;
    margin-top: 30px;
}

.destacats-data {
    float: left;
    font-size: 12px;
    color: #aaa;
}

.noticies {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    align-items: center;
    gap: 50px;
    padding: 50px;
}

.noticies-blog {
    order: 1;
    background-color: #fff;
    border: none;
    border-radius: 15px;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
    height: 500px;
    cursor: pointer;
}

.noticies-blog-imatge {
    border-radius: 15px 15px 0px 0px;
    max-height: 300px;
}

@media (max-width: 737px) {
    .noticies {
        justify-content: center;
    }

    .noticies-blog {
        width: 300px;
    }

    .noticies-blog-imatge {
        width: 300px;
    }

    .menu {
        display: flex;
    }

    .me-auto {
        margin-right: auto !important;
        margin-top: 20px;
        height: 800px;
    }

    .navegacio {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 18px;
        margin-top: 6px;
    }

    .barriomenu {
        padding-left: 7px;
        margin-right: 0;
    }

    .columna {
        columns: 2;
    }

    .ReactFlagsSelect-module_selectValue__152eS {
        font-size: 18px;
    }

    .ReactFlagsSelect-module_selectBtn__19wW7  {
        cursor: pointer;
        width: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
        font-family: inherit;
        color: #4d4d4d;
        border: thin solid rgba(77, 77, 77, 0.3);
        border-radius: 4px;
        background: transparent;
        font-size: 22px !important;
        margin-left: -15px;
    }

    .ReactFlagsSelect-module_selectOptions__3LNBJ {
        margin-top: 0px;
    }

    .caixa-home {
        top: 97px;
    }

    .react-datepicker {
        width: min-content;
    }

    ul.multiple-sticky-nav {
        display: none;
    }

    .caixa-seccions {
        padding: 0px 24px;
    }
}

@media (min-width: 738px) {
    .noticies {
        justify-content: left;
    }

    .noticies-blog {
        width: 365px;
    }

    .noticies-blog-imatge {
        width: 370px;
    }
}

.noticies-blog-text {
    padding: 10px;
    text-align: left;
    margin-bottom: 10px;
}

.noticies-blog-titol {
    font-size: 18px;
    display: block;
    margin: 25px;
    text-align: left;
}

.noticia-individual,
.centra {
    text-align: center;
}

.noticia-individual-imatge {
    border-radius: 15px;
    margin: 30px;
    max-width: 800px;
}

@media (max-width: 737px) {
    .noticia-individual-descripcio-curta {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
    }

    .noticia-individual-descripcio-llarga {
        margin-left: 5%;
        margin-right: 5%;
        font-size: 18px;
        line-height: 30px;
        text-align: justify;
        padding: 0 10px;
    }

    .imatge-banner {
        background-attachment: fixed;
        height: 500px;
        margin: 10px;
        border-radius: 15px;
    }

    .caixa-seccions {
        position: relative;
    }

    .calendari-fitxa-propietat {
        margin-bottom: 25px;
    }

    .caixa-home {
        top: 100px;
        margin-top: -200px;
        margin-bottom: 35px !important;
    }
}

@media (min-width: 738px) and (max-width: 900px) {
    .caixa-home {
        width: 90% !important;
    }

    .buscador-inputs {
        flex: auto !important;
    }

    .menu {
        display: flex;
    }

    .me-auto {
        margin-right: auto !important;
        margin-top: 20px;
        height: 1200px;
    }

    .navegacio {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 25px;
        margin-top: 6px;
    }
}

@media (min-width: 738px) {
    .noticia-individual-descripcio-curta {
        margin-left: 250px;
        margin-right: 250px;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
    }

    .noticia-individual-descripcio-llarga {
        margin-left: 10%;
        margin-right: 10%;
        font-size: 18px;
        line-height: 30px;
        text-align: justify;
    }

    .imatge-banner {
        /* background-attachment: fixed; */
        height: 400px;
        margin: 50px;
        border-radius: 15px;
    }
}

.destacats-equip {
    order: 1;
    background-color: #fff;
    border: none;
    border-radius: 15px;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
    min-height: 620px;
    width: 300px;
    cursor: pointer;
}

.destacats-imatge-equip {
    width: 100%;
    border-radius: 15px 15px 0px 0px;
    height: auto;
}

.destacats-text-equipo {
    padding: 20px;
    text-align: center;
    background: #fff;
    border-radius: 0px 0px 15px 15px;
}

.letrablanca {
    color: #fff;
}

.leaflet-container {
    height: 500px;
    margin: 45px;
    border-radius: 18px;
    box-shadow: 1px 1px 6px 0px rgba(186, 186, 186, 0.25);
}

.featured-timeline {
    position: relative;
}

.featured-timeline ul {
    margin: 0;
    padding: 0;
}

.featured-timeline ul li:nth-child(odd) {
    text-align: right;
    padding-left: 30px;
    padding-right: 110px;
}

.featured-timeline ul li {
    width: 50%;
    float: left;
    padding: 30px 0;
    padding-left: 110px;
    padding-right: 30px;
    position: relative;
}

.featured-timeline ul li:nth-child(odd) .icon {
    left: auto;
    right: 30px;
}

.featured-timeline ul li .icon {
    position: absolute;
    top: 30px;
    left: 30px;
    font-size: 24px;
    width: 50px;
    height: 50px;
    line-height: 48px;
    border: 2px solid #7f8c8d !important;
    border-radius: 50%;
    text-align: center;
    color: #7f8c8d !important;
}

.featured-timeline ul li h4 {
    margin: 0 0 10px;
    text-transform: uppercase;
}

@media (max-width: 737px) {
    .featured-timeline ul li {
        width: 100%;
        float: none;
        padding: 0;
    }

    .featured-timeline ul li:nth-child(odd),
    .featured-timeline ul li:nth-child(even) {
        text-align: center;
        padding: 10px 0 10px 0;
    }

    .featured-timeline ul li:nth-child(odd) .icon,
    .featured-timeline ul li:nth-child(even) .icon {
        position: initial;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
    }
}

.bg-grey {
    background: #fbfbfb;
}

.travel-quote-banner {
    background-size: cover;
    background-position: center center;
    padding: 50px 0;
}

.travel-quote-banner .content {
    width: 40%;
    margin: 50px auto;
    color: #fff;
    text-align: right;
}

.travel-quote-banner .saying {
    font-size: 30px;
    text-transform: uppercase;
    line-height: 32px;
}

.travel-quote-banner .sayer {
    font-size: 16px;
}

.travel-quote-banner .content a:hover {
    background: #000;
    color: #fff;
    border: 2px solid #000;
}

.travel-quote-banner .content a {
    background: #7f8c8d !important;
}

.travel-quote-banner .content a {
    color: #fff;
    border: 2px solid #c6b415;
    padding: 10px 15px;
    text-transform: uppercase;
    line-height: 1;
    background: #c6b415;
}

@media (max-width: 991px) {
    .travel-quote-banner {
        display: block;
    }
}

@media (min-width: 300px) and (max-width: 786px) {
    .travel-quote-banner .content {
        width: 70%;
    }
}

.destinosbanner a {
    color: #7f8c8d;
}

/* .destinosbanner a.whitelink{
  color: #FFF;
}*/

@media screen and (max-width: 768px) {
    figcaption {
        margin: 0px;
    }
}

.fondo-buscador {
    width: 100%;
    max-width: 1320px;
    border-radius: 15px;
    background-color: #fff;
    pointer-events: auto;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
    padding: 1rem 1.875rem;
}

.two-column-css {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 40px;
    -moz-column-gap: 40px;
    column-gap: 40px;
}

@media (min-width: 992px) {
    .appear-sm,
    .followWrap {
        display: none !important;
    }
}

@media (min-width: 992px) {
    .followWrap {
        display: block !important;
    }
}

.multiple-sticky {
    position: relative;
    z-index: 1001;
}

.multiple-sticky .multiple-sticky-item {
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
}

ul.multiple-sticky-nav {
    padding: 10px 10px;
    background: #fff;
    border-bottom: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
    border-radius: 15px;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
}

ul.multiple-sticky-nav > li {
    display: inline-block;
    margin: 0;
    padding: 0;
}

ul.multiple-sticky-nav > li a:hover {
    color: #635d9e;
}

ul.multiple-sticky-nav > li a {
    padding: 15px 10px;
    line-height: 1;
    display: block;
    position: relative;
    border: 1px solid transparent;
    border-top: 0;
    padding-top: 16px;
    margin-bottom: -1px;
    color: #5d5d5d;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.5px;
}

ul.multiple-sticky-nav > li.active a {
    background: #f7f7f7;
    border-color: #e5e5e5;
    border-bottom-color: #f7f7f7;
    color: #b99e00;
}

ul.multiple-sticky-nav > li.active a {
    color: #635d9e;
}

.featured-bg {
    padding: 180px 0;
    height: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.featured-bg h1,
.featured-bg h3,
.featured-bg p {
    text-shadow: 2px 2px #000;
}

.featured-bg h3 {
    margin-top: 0;
    font-size: 34px;
    color: #fff;
    font-weight: 600;
    line-height: 34px;
    text-transform: uppercase;
    margin: 0 0 10px;
    text-align: center;
}

.featured-bg p {
    color: #fff;
    font-size: 26px;
    line-height: 30px;
    font-weight: 300;
    font-family: Lato, sans-serif;
    text-align: center;
}

.camiformiga {
    background: #ededed;
    padding-top: 15px;
    padding-bottom: 13px;
}

.featured-box {
    text-align: center;
    border: 1px solid #e5e5e5;
    padding: 30px;
    margin: 30px 0 10px;
    border-radius: 15px;
}

.icon.bg-primary {
    background: #e6e6e6 !important;
}

.featured-box .icon {
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 24px;
    margin: 0 auto 30px;
    margin-top: -60px;
    border-radius: 50%;
}

.featured-box h4,
.featured-box p {
    margin: 0 0 15px;
}

.featured-box a {
    color: #636363;
    font-size: 12px;
    font-weight: 600;
}

h5.footer-title:after {
    background: #7f8c8d !important;
}

h5.footer-title:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -11px;
    width: 40px;
    height: 2px;
    background: #b99e00;
}

ul.recent-post-sm li .image {
    width: 80px;
    float: left;
}

ul.recent-post-sm li .content {
    margin-left: 90px;
}

ul.recent-post-sm li h3 {
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 5px;
    font-weight: 500;
}

.bb {
    border-bottom: 1px solid #e5e5e5;
}

.no-background {
    background-color: rgba(255, 255, 255, 0);
    width: 40px;
}

.centradoalineado {
    vertical-align: bottom;
    text-align: center;
}

.descripcions-propietat {
    padding-left: 0px;
}

ul.descripcions-propietat > li {
    position: relative;
    line-height: 30px;
    margin-bottom: 20px;
}

ul.descripcions-propietat li {
    padding-left: 0px;
}

ul.descripcions-propietat div {
    padding-left: 35px;
    margin-bottom: 20px;
}

.diainvalido {
    text-align: center;
}

.diainvalidopasado {
    text-align: center;
    background: #efefef;
    color: #000;
    cursor: not-allowed;
}

.diabloqueado {
    text-align: center;
    background: #aaa;
    color: #fff;
}

.diavalido {
    text-align: center;
    cursor: pointer;
}

ul.map-neighbour-label {
    float: right;
    margin: 0;
    padding: 0;
    margin-top: 10px;
}

ul.map-neighbour-label {
    float: left;
}

ul.map-neighbour-label li {
    position: relative;
    float: left;
    padding-left: 15px;
    margin-left: 30px;
    line-height: 1;
}

ul.map-neighbour-label li {
    margin-right: 30px;
    margin-left: 0;
}

ul.map-neighbour-label li:before {
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    width: 10px;
    height: 10px;
    border-radius: 6px;
    background: #14205d;
}

ul.map-neighbour-label li.color-04:before {
    background: #aaa;
}

.featured-item-2 .icon {
    float: left;
    font-size: 30px;
    margin-top: -4px;
    margin-right: 15px;
}

.caixa-preus {
    border: 1px solid rgb(221, 221, 221);
    border-radius: 15px;
    padding: 24px;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
    position: sticky;
    top: 120px;
    z-index: 10;
}

.caixa-seccions {
    top: 97px;
    padding: 39px 24px;
    position: sticky;
    z-index: 1001;
}

.caixa-cercadors {
    top: 95px;
    position: sticky;
    z-index: 1001;
}

.caixa-home {
    padding: 24px;
    top: 88px;
    position: sticky;
    background: white;
    width: 70%;
    margin-top: -275px;
    margin-bottom: 180px;
}

@media only screen and (min-width: 600px) {
    .ajuda {
        width: 22em;
        padding-bottom: 1em;
    }
}

.formpersonas {
    border: none;
    border-radius: 15px;
    box-shadow: 10px 10px 10px 10px rgba(186, 186, 186, 0.25);
    margin-top: 50px;
    z-index: 100;
    padding: 10px 26px;
    background: #ffffff;
    position: absolute;
    text-align: left;
    min-width: 280px;
    max-width: 400px;
}

#formGridDestino {
    width: 100%;
}

.step-item {
    color: #636363;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
}

.step-item.active .line {
    background: #000;
}

.step-item .line {
    width: 100%;
    height: 1px;
    background: #d1d1d1;
}

.step-item .number {
    width: 40px;
    height: 40px;
    background: #ededed;
    margin: -19px auto 0;
    padding: 5px;
    border-radius: 50%;
}

.step-item.active .number .inner {
    background: #7f8c8d !important;
}

.step-item .number .inner {
    background: #d1d1d1;
    height: 100%;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    border-radius: 50%;
}

.breadcrumb-wrapper {
    margin-top: 35px;
}

.breadcrumb-wrapper {
    background: #ededed;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    padding-top: 15px;
    padding-bottom: 13px;
}

.metro-box-wrapper {
    margin: 0 0 30px;
}

.metro-box-wrapper > .content {
    padding: 40px 25px 25px;
}

.metro-box-wrapper > .heading {
    background: #e6e6e6;
    padding: 24px 20px 18px;
    line-height: 18px;
    border-bottom: 1px solid #dedede;
}

.metro-box-wrapper > .heading h3 {
    text-transform: uppercase;
    font-size: 26px;
    line-height: 1.2;
    margin: 0;
}

.tarifes {
    position: relative;
    margin: 0 0 15px;
}

.tarifes .icon {
    border: 1px solid #7f8c8d !important;
    background: #7f8c8d !important;
    width: 130px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
}

.tarifes .icon span {
    font-size: 25px;
    color: #fff;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin-top: -10px;
}

.form-horizontal .control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right;
}

.form-group {
    margin-bottom: 15px;
}

.payment-option-wrapper .radio-block {
    margin-left: 10px;
    line-height: 40px;
}

.font-icon-checkbox input[type="checkbox"] + label,
.font-icon-radio input[type="radio"] + label {
    background: 0;
    position: relative;
    padding-left: 20px;
}

.payment-option-wrapper .radio-block span {
    display: block;
    float: left;
    font-weight: 700;
    font-size: 14px;
    color: #555;
}

.payment-congrate {
    border: 1px solid #000;
    background: #000;
    color: #fff;
    margin: 0 0 30px;
    text-align: center;
    font-size: 14px;
}

.payment-congrate > .inner {
    border: 3px double #fff;
    padding: 50px 100px 30px;
}

.payment-congrate .lead {
    font-size: 24px;
    margin: 0 0 10px;
}

.payment-congrate .payment-term {
    font-size: 13px;
}

.payment-congrate a {
    color: #fff;
    text-decoration: underline;
}

.payment-congrate .btn {
    box-shadow: 0 8px 18px rgba(0, 0, 0, 0.22), 0 6px 6px rgba(0, 0, 0, 0.26);
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    padding: 8px 14px 7px;
}

.payment-congrate .btn:hover {
    color: #fff;
    background: #7f8c8d !important;
}

.payment-congrate .btn {
    margin: 10px 0 20px;
    background: #fff;
    color: #000;
    border: 3px double #000;
}

.tarifes .content {
    border: 1px solid rgb(221, 221, 221);
    background: #fff;
    line-height: 20px;
    padding: 1px 15px 0 15px;
    color: #555;
    border-radius: 15px;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
}

.mt-checkbox,
.mt-radio {
    display: inline-block;
    position: relative;
    padding-left: 10px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s;
}

.mt-radio > input:checked ~ span {
    transition: all 0.3s;
}

.mt-radio > span {
    border-radius: 50% !important;
}

.mt-radio > span,
.timeline .timeline-badge-userpic img {
    border-radius: 50% !important;
}

.mt-checkbox > span,
.mt-radio > span {
    border: 1px solid transparent;
    position: absolute;
    top: 1px;
    left: 0;
    height: 18px;
    width: 18px;
}

.mt-radio > span:after {
    left: 5px;
    top: 5px;
    height: 6px;
    width: 6px;
    border-radius: 100% !important;
    background: #888;
}

.dropdown-menu {
    //margin-top: 25px !important;
    border: none;
    box-shadow: 10px 10px 10px 10px rgba(186, 186, 186, 0.25);
    //overflow-y: scroll;
    scroll-behavior: smooth;
    //max-height: 16em;
}

.icones-propietat {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    align-items: center;
    padding: 10px;
}

.linia {
    border-top-width: 1px !important;
    border-top-style: solid !important;
    border-top-color: rgb(221, 221, 221);
}

.btn-serveis {
    color: #000;
    background-color: var(--fons);
    border-color: #000;
}

@media (max-width: 737px) {
    .btn-serveis {
        margin-left: 70px;
    }
}

.btn-serveis:hover {
    color: var(--corporatiu);
    background-color: var(--fons);
    border-color: #000;
}

/* GALERIA D'IMATGES */

@media (min-width: 800px) {
    section.photo_grid button {
        font: inherit;
        margin: 0;
        padding: 0;
        background: none;
        border-radius: 0;
        border: 0;
        outline: none;
        cursor: pointer;
    }
    section.photo_grid.photoGrid_sizeLarge .container .inner {
        max-width: 1450px;
    }
    section.photo_grid.photoGrid_sizeLarge .photo_grid-wrapper {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 6.3fr 1.85fr 1.85fr;
        grid-template-areas: "image-1 image-2 image-3" "image-1 image-4 image-5";
        overflow: hidden;
        grid-gap: 15px;
        margin-left: -40px;
    }
    section.photo_grid.photoGrid_sizeLarge
        .photo_grid-wrapper
        li:nth-of-type(1)
        button:before {
        content: "";
        display: block;
        padding-top: 56.23632385%;
    }

    section.photo_grid.photoGrid_sizeThumbs .container .inner {
        max-width: 41.875rem;
    }
    section.photo_grid.photoGrid_sizeThumbs .photo_grid-wrapper {
        display: flex;
        flex-wrap: wrap;
        max-width: 41.875rem;
        width: 100%;
        margin: 0 auto;
        position: relative;
    }
    section.photo_grid.photoGrid_sizeThumbs
        .photo_grid-wrapper
        li:nth-of-type(1) {
        flex: 0 0 100%;
        width: 100%;
        margin-bottom: 1rem;
        height: 23.5rem;
    }
    section.photo_grid.photoGrid_sizeThumbs
        .photo_grid-wrapper
        li:nth-of-type(1)
        button {
        height: 100%;
    }
    section.photo_grid.photoGrid_sizeThumbs
        .photo_grid-wrapper
        li:nth-of-type(1)
        button:before {
        display: block;
        padding-top: 56.23632385%;
    }
    section.photo_grid.photoGrid_sizeThumbs
        .photo_grid-wrapper
        li:nth-of-type(1n + 2) {
        flex: 0 0 4rem;
        width: 4rem;
    }
    section.photo_grid.photoGrid_sizeThumbs
        .photo_grid-wrapper
        li:nth-of-type(1n + 2)
        button:before {
        content: "";
        display: block;
        padding-top: 100%;
    }
    section.photo_grid.photoGrid_sizeThumbs
        .photo_grid-wrapper
        li:nth-of-type(1n + 2)
        button
        img {
        position: absolute;
        top: 0;
    }
    section.photo_grid.photoGrid_sizeThumbs
        .photo_grid-wrapper
        li:nth-of-type(1n + 2)
        + li {
        margin-left: 1rem;
    }
    section.photo_grid.photoGrid_sizeThumbs .photo_grid-more {
        bottom: auto;
        top: calc(100% - 64px - 16px);
        transform: translateY(calc(-100% - 16px));
    }
    section.photo_grid .container .inner {
        margin: 0 auto;
        position: relative;
    }
    section.photo_grid .photo_grid-wrapper li.indicators {
        display: none;
    }
    section.photo_grid .photo_grid-wrapper li {
        position: relative;
        overflow: hidden;
    }
    section.photo_grid .photo_grid-wrapper li button:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0);
        transition: all 0.5s ease-in-out;
    }
    section.photo_grid .photo_grid-wrapper li button.notHover:after {
        box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.2);
    }
    section.photo_grid .photo_grid-wrapper li:hover {
        z-index: 2;
    }
    section.photo_grid .photo_grid-wrapper li:hover button:hover img {
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        transform: translate(-10px, -10px);
    }
    section.photo_grid .photo_grid-wrapper li img {
        width: 100%;
        height: auto;
        display: block;
        transition: all 0.5s ease-in-out;
    }
    section.photo_grid .photo_grid-wrapper li button {
        display: block;
        width: 100%;
    }
    section.photo_grid .photo_grid-wrapper li:nth-of-type(1) {
        grid-area: image-1;
        border-radius: 15px 0px 0px 15px;
    }
    section.photo_grid .photo_grid-wrapper li:nth-of-type(2) {
        grid-area: image-2;
    }
    section.photo_grid .photo_grid-wrapper li:nth-of-type(3) {
        grid-area: image-3;
        border-radius: 0px 15px 0px 0px;
    }
    section.photo_grid .photo_grid-wrapper li:nth-of-type(4) {
        grid-area: image-4;
    }
    section.photo_grid .photo_grid-wrapper li:nth-of-type(5) {
        grid-area: image-5;
        border-radius: 0px 0px 15px 0px;
    }
    section.photo_grid .photo_grid-wrapper li:nth-of-type(1) button {
        position: relative;
    }
    section.photo_grid .photo_grid-wrapper li:nth-of-type(1) button img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }
    section.photo_grid .photo_grid-wrapper li:nth-of-type(1n + 2) button {
        height: 100%;
        left: 0;
        top: 0;
    }
    section.photo_grid .photo_grid-wrapper li:nth-of-type(1n + 2) button img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    section.photo_grid .photo_grid-fullscreen,
    section.photo_grid .photo_grid-more {
        z-index: 3;
    }
    section.photo_grid .photo_grid-fullscreen button,
    section.photo_grid .photo_grid-more button {
        transition: all 0.15s ease-in-out;
    }
    section.photo_grid .photo_grid-fullscreen button:hover,
    section.photo_grid .photo_grid-more button:hover {
        transform: translateY(3px);
        box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.25);
    }
    section.photo_grid .photo_grid-more {
        position: absolute;
        right: 16px;
        bottom: 16px;
    }
    section.photo_grid .photo_grid-more button {
        background: white;
        color: black;
        padding: 0 8px;
        line-height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.45);
    }
    section.photo_grid .photo_grid-more button[count]:after {
        content: "\00a0("attr(count) ")";
        font-weight: normal;
    }
    section.photo_grid .photo_grid-more button:before {
        display: block;
        order: 3;
        margin-left: 8px;
        font-size: 1.3em;
    }
    section.photo_grid .photo_grid-fullscreen {
        position: absolute;
        right: 16px;
        top: 16px;
    }
    section.photo_grid .photo_grid-fullscreen button {
        background: white;
        color: black;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        border-radius: 3px;
        box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.45);
        font-size: 0;
    }
    section.photo_grid .photo_grid-fullscreen button[icon]:after {
        content: attr(icon);
        font-weight: normal;
        font-size: 20px;
        color: blue;
    }
}
@media (max-width: 800px) {
    section.photo_grid button {
        font: inherit;
        margin: 0;
        padding: 0;
        background: none;
        border-radius: 0;
        border: 0;
        outline: none;
        cursor: pointer;
        display: block;
    }
    section.photo_grid.photoGrid_sizeLarge .container {
        overflow: hidden;
    }
    section.photo_grid.photoGrid_sizeLarge .container .inner {
        display: flex;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
    }
    section.photo_grid.photoGrid_sizeLarge .photo_grid-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
    }
    section.photo_grid.photoGrid_sizeLarge .photo_grid-wrapper:after {
        position: sticky;
        bottom: 0;
        background: white;
        display: block;
        line-height: 35px;
        padding: 0 8px;
        z-index: 4;
        right: 0;
        width: 100%;
        white-space: nowrap;
        font-size: 14px;
        align-self: flex-end;
        width: 110px;
        text-align: center;
        transform: translate(-8px, -8px);
        margin-left: -110px;
        pointer-events: none;
        border-radius: 2px;
    }
    section.photo_grid.photoGrid_sizeLarge .photo_grid-wrapper li {
        flex: 0 0 100vw;
        width: 100vw;
    }
    section.photo_grid.photoGrid_sizeLarge .photo_grid-wrapper li img {
        width: 100%;
        height: 100%;
        display: block;
    }
    section.photo_grid.photoGrid_sizeLarge .photo_grid-wrapper li button {
        position: relative;
        width: 100%;
    }
    section.photo_grid.photoGrid_sizeLarge
        .photo_grid-wrapper
        li
        button:before {
        content: "";
        display: block;
        padding-top: 56.25%;
    }
    section.photo_grid.photoGrid_sizeLarge .photo_grid-wrapper li button img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: -65px;
        display: block;
        padding-right: 4px;
    }
    section.photo_grid.photoGrid_sizeLarge .photo_grid-wrapper li.indicators {
        position: sticky;
        width: 63px;
        right: 0;
        display: flex;
        align-self: flex-end;
        justify-content: center;
        padding-bottom: 19px;
        pointer-events: none;
        margin-left: -63px;
        flex: 0 0 63px;
        transform: translateX(calc(-50vw + (63px / 2)));
    }
    section.photo_grid.photoGrid_sizeLarge
        .photo_grid-wrapper
        li.indicators
        i
        + i {
        margin-left: 7px;
    }
    section.photo_grid.photoGrid_sizeLarge .photo_grid-wrapper li.indicators i {
        width: 7px;
        height: 7px;
        display: block;
        border: 1px solid white;
        font-size: 0;
        overflow: hidden;
    }
    section.photo_grid.photoGrid_sizeLarge
        .photo_grid-wrapper
        li.indicators
        i.active {
        background: white;
    }
    section.photo_grid.photoGrid_sizeLarge .photo_grid-more {
        position: relative;
        height: 56.25vw;
        flex: 0 0 50vw;
    }
    section.photo_grid.photoGrid_sizeLarge .photo_grid-more:before {
        padding-top: 56.25%;
        display: block;
        width: 100vw;
    }
    section.photo_grid.photoGrid_sizeLarge .photo_grid-more button {
        background: white;
        color: black;
        padding: 0 8px;
        line-height: 35px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 100%;
        width: 100%;
        color: white;
        top: 0;
        left: 0;
        padding-top: 50px;
    }

    section.photo_grid.photoGrid_sizeLarge .photo_grid-more button:before {
        display: block;
        color: white;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid white;
        border-radius: 50%;
        position: absolute;
        transform: translateY(-100%);
        font-size: 30px;
    }
    section.photo_grid.photoGrid_sizeLarge .photo_grid-fullscreen {
        display: none;
    }
    section.photo_grid.photoGrid_sizeThumbs .container .inner,
    section.photo_grid.photoGrid_sizeNormal .container .inner,
    section.photo_grid.photoGrid_sizeSmall .container .inner {
        margin: 0 auto;
        position: relative;
    }
    section.photo_grid.photoGrid_sizeThumbs .photo_grid-wrapper,
    section.photo_grid.photoGrid_sizeNormal .photo_grid-wrapper,
    section.photo_grid.photoGrid_sizeSmall .photo_grid-wrapper {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 7.6163fr 2.3837fr;
        grid-template-areas: "image-1 image-2" "image-1 image-3";
        overflow: hidden;
        grid-gap: 8px;
    }
    section.photo_grid.photoGrid_sizeThumbs .photo_grid-wrapper li.indicators,
    section.photo_grid.photoGrid_sizeNormal .photo_grid-wrapper li.indicators,
    section.photo_grid.photoGrid_sizeSmall .photo_grid-wrapper li.indicators {
        display: none;
    }
    section.photo_grid.photoGrid_sizeThumbs .photo_grid-wrapper li,
    section.photo_grid.photoGrid_sizeNormal .photo_grid-wrapper li,
    section.photo_grid.photoGrid_sizeSmall .photo_grid-wrapper li {
        position: relative;
        overflow: hidden;
    }
    section.photo_grid.photoGrid_sizeThumbs .photo_grid-wrapper li img,
    section.photo_grid.photoGrid_sizeNormal .photo_grid-wrapper li img,
    section.photo_grid.photoGrid_sizeSmall .photo_grid-wrapper li img {
        width: 100%;
        height: auto;
        display: block;
        transition: all 0.5s ease-in-out;
    }
    section.photo_grid.photoGrid_sizeThumbs .photo_grid-wrapper li button,
    section.photo_grid.photoGrid_sizeNormal .photo_grid-wrapper li button,
    section.photo_grid.photoGrid_sizeSmall .photo_grid-wrapper li button {
        display: block;
        width: 100%;
    }
    section.photo_grid.photoGrid_sizeThumbs
        .photo_grid-wrapper
        li:nth-of-type(1),
    section.photo_grid.photoGrid_sizeNormal
        .photo_grid-wrapper
        li:nth-of-type(1),
    section.photo_grid.photoGrid_sizeSmall
        .photo_grid-wrapper
        li:nth-of-type(1) {
        grid-area: image-1;
    }
    section.photo_grid.photoGrid_sizeThumbs
        .photo_grid-wrapper
        li:nth-of-type(2),
    section.photo_grid.photoGrid_sizeNormal
        .photo_grid-wrapper
        li:nth-of-type(2),
    section.photo_grid.photoGrid_sizeSmall
        .photo_grid-wrapper
        li:nth-of-type(2) {
        grid-area: image-2;
    }
    section.photo_grid.photoGrid_sizeThumbs
        .photo_grid-wrapper
        li:nth-of-type(3),
    section.photo_grid.photoGrid_sizeNormal
        .photo_grid-wrapper
        li:nth-of-type(3),
    section.photo_grid.photoGrid_sizeSmall
        .photo_grid-wrapper
        li:nth-of-type(3) {
        grid-area: image-3;
    }
    section.photo_grid.photoGrid_sizeThumbs
        .photo_grid-wrapper
        li:nth-of-type(3)
        button:before,
    section.photo_grid.photoGrid_sizeNormal
        .photo_grid-wrapper
        li:nth-of-type(3)
        button:before,
    section.photo_grid.photoGrid_sizeSmall
        .photo_grid-wrapper
        li:nth-of-type(3)
        button:before {
        background: rgba(0, 33, 93, 0.38);
        z-index: 2;
        position: relative;
    }
    section.photo_grid.photoGrid_sizeThumbs
        .photo_grid-wrapper
        li:nth-of-type(1n + 4),
    section.photo_grid.photoGrid_sizeNormal
        .photo_grid-wrapper
        li:nth-of-type(1n + 4),
    section.photo_grid.photoGrid_sizeSmall
        .photo_grid-wrapper
        li:nth-of-type(1n + 4) {
        display: none;
    }
    section.photo_grid.photoGrid_sizeThumbs
        .photo_grid-wrapper
        li:nth-of-type(1)
        button,
    section.photo_grid.photoGrid_sizeNormal
        .photo_grid-wrapper
        li:nth-of-type(1)
        button,
    section.photo_grid.photoGrid_sizeSmall
        .photo_grid-wrapper
        li:nth-of-type(1)
        button {
        position: relative;
        height: 100%;
    }
    section.photo_grid.photoGrid_sizeThumbs
        .photo_grid-wrapper
        li:nth-of-type(1)
        button
        img,
    section.photo_grid.photoGrid_sizeNormal
        .photo_grid-wrapper
        li:nth-of-type(1)
        button
        img,
    section.photo_grid.photoGrid_sizeSmall
        .photo_grid-wrapper
        li:nth-of-type(1)
        button
        img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
    }
    section.photo_grid.photoGrid_sizeThumbs
        .photo_grid-wrapper
        li:nth-of-type(1n + 2)
        button,
    section.photo_grid.photoGrid_sizeNormal
        .photo_grid-wrapper
        li:nth-of-type(1n + 2)
        button,
    section.photo_grid.photoGrid_sizeSmall
        .photo_grid-wrapper
        li:nth-of-type(1n + 2)
        button {
        height: 100%;
        left: 0;
        top: 0;
    }
    section.photo_grid.photoGrid_sizeThumbs
        .photo_grid-wrapper
        li:nth-of-type(1n + 2)
        button:before,
    section.photo_grid.photoGrid_sizeNormal
        .photo_grid-wrapper
        li:nth-of-type(1n + 2)
        button:before,
    section.photo_grid.photoGrid_sizeSmall
        .photo_grid-wrapper
        li:nth-of-type(1n + 2)
        button:before {
        content: "";
        display: block;
        padding-top: 100%;
    }
    section.photo_grid.photoGrid_sizeThumbs
        .photo_grid-wrapper
        li:nth-of-type(1n + 2)
        button
        img,
    section.photo_grid.photoGrid_sizeNormal
        .photo_grid-wrapper
        li:nth-of-type(1n + 2)
        button
        img,
    section.photo_grid.photoGrid_sizeSmall
        .photo_grid-wrapper
        li:nth-of-type(1n + 2)
        button
        img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }
    section.photo_grid.photoGrid_sizeThumbs .photo_grid-more,
    section.photo_grid.photoGrid_sizeNormal .photo_grid-more,
    section.photo_grid.photoGrid_sizeSmall .photo_grid-more {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 2;
        width: calc(23.837% - 2px);
    }
    section.photo_grid.photoGrid_sizeThumbs .photo_grid-more:before,
    section.photo_grid.photoGrid_sizeNormal .photo_grid-more:before,
    section.photo_grid.photoGrid_sizeSmall .photo_grid-more:before {
        padding-top: 100%;
        display: block;
        content: "";
    }
    section.photo_grid.photoGrid_sizeThumbs .photo_grid-more button,
    section.photo_grid.photoGrid_sizeNormal .photo_grid-more button,
    section.photo_grid.photoGrid_sizeSmall .photo_grid-more button {
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    section.photo_grid.photoGrid_sizeThumbs .photo_grid-more button span.more,
    section.photo_grid.photoGrid_sizeNormal .photo_grid-more button span.more,
    section.photo_grid.photoGrid_sizeSmall .photo_grid-more button span.more {
        display: none;
    }
    section.photo_grid.photoGrid_sizeThumbs
        .photo_grid-more
        button[count]:before,
    section.photo_grid.photoGrid_sizeNormal
        .photo_grid-more
        button[count]:before,
    section.photo_grid.photoGrid_sizeSmall
        .photo_grid-more
        button[count]:before {
        font-weight: normal;
    }
    section.photo_grid.photoGrid_sizeThumbs .photo_grid-fullscreen,
    section.photo_grid.photoGrid_sizeNormal .photo_grid-fullscreen,
    section.photo_grid.photoGrid_sizeSmall .photo_grid-fullscreen {
        display: none;
    }
}

.boto-cercle {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: 1px solid rgb(153, 153, 153);
    background: none;
}

.centrar-boto-cercle {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.etiqueta-formulari {
    font-size: 20px;
    padding: 15px;
}

.mini-etiqueta-formulari {
    font-size: 12px;
    color: #a6a6a6;
}

.numero-persones {
    text-align: center;
    border: none;
    padding: 2px 1px 1px 1px;
    background: none;
    color: #a6a6a6;
}

.sense-border {
    border: none;
}

.boto-cercle {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: 1px solid rgb(153, 153, 153);
    background: none;
}

.centrar-boto-cercle {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.etiqueta-formulari {
    font-size: 20px;
    padding: 15px;
}

.mini-etiqueta-formulari {
    font-size: 12px;
    color: #a6a6a6;
}

.numero-persones {
    text-align: center;
    border: none;
    padding: 2px 1px 1px 1px;
    background: none;
    color: #a6a6a6;
}

.sense-border {
    border: none;
}

.gris {
    color: #888;
    font-size: 14px;
}

.llegir-mes {
    color: #888;
    font-size: 15px;
    margin-left: 25px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.h47 {
    height: 47px;
}

.tamcaptcha {
    width: 304px;
    height: 78px;
}

.tamtextcaptcha {
    width: 250px;
    height: 40px;
    border: 1px solid rgb(193, 193, 193);
    margin: 10px 25px;
    padding: 0px;
    resize: none;
    display: none;
}

.noshow {
    display: none;
}

.show {
    display: block;
}

.h38 {
    height: 38px;
}

.h190 {
    height: 190px;
}

.pillsfaqs,
.navleft {
    background: #fff;
    margin-top: 10px;
}

.justificar {
    text-align: justify;
}

.bf7 {
    background: #f7f7f7;
}

.imagendestino {
    height: auto;
    width: 100%;
}

.iconoprop {
    width: 40px;
    height: 40px;
    margin-bottom: -25px;
}

.op08 {
    opacity: 0.8;
}

.font30 {
    font-size: 30px;
}

.cpointer {
    cursor: pointer;
}

.c008 {
    color: #008000;
}

/* CALENDARI */

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
    border-color: #ccc;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 6px;
    width: 9px;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    margin-left: -4px;
    position: absolute;
    width: 0;
}
.react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::after {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px;
}
.react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before {
    border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
}
.react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: #f0f0f0;
}
.react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::after {
    top: 0;
}
.react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
    bottom: 0;
    margin-bottom: -8px;
}
.react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::after {
    border-bottom: none;
    border-top-color: #fff;
}
.react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::after {
    bottom: 0;
}
.react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::before {
    bottom: -1px;
    border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100%;
    cursor: pointer;
}

.react-datepicker {
    font-size: 14px;
    background-color: #fff;
    border-radius: 15px;
    display: inline-block;
    position: relative;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
    width: max-content;
}

.react-datepicker--time-only .react-datepicker__triangle {
    left: 35px;
}
.react-datepicker--time-only .react-datepicker__time-container {
    border-left: 0;
}
.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.react-datepicker__triangle {
    position: absolute;
    left: 50px;
}

.react-datepicker-popper {
    z-index: 1;
}
.react-datepicker-popper[data-placement^="bottom"] {
    padding-top: 10px;
}
.react-datepicker-popper[data-placement="bottom-end"]
    .react-datepicker__triangle,
.react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
    left: auto;
    right: 50px;
}
.react-datepicker-popper[data-placement^="top"] {
    padding-bottom: 10px;
}
.react-datepicker-popper[data-placement^="right"] {
    padding-left: 8px;
}
.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
    left: auto;
    right: 42px;
}
.react-datepicker-popper[data-placement^="left"] {
    padding-right: 8px;
}
.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
    left: 42px;
    right: auto;
}

.react-datepicker__header {
    text-align: center;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 11px;
    padding: 20px 1px 20px 1px;
    position: relative;
}

.react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
}
.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
    border-top-left-radius: 0;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 15px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 15px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 0.944rem;
}

.react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.react-datepicker__navigation {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 15px;
    padding: 0;
    border: none;
    z-index: 1;
    height: 32px;
    width: 32px;
    text-indent: -999em;
    overflow: hidden;
}
.react-datepicker__navigation--previous {
    left: 2px;
}
.react-datepicker__navigation--next {
    right: 2px;
}
.react-datepicker__navigation--next--with-time:not(
        .react-datepicker__navigation--next--with-today-button
    ) {
    right: 85px;
}
.react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.react-datepicker__navigation--years-previous {
    top: 4px;
}
.react-datepicker__navigation--years-upcoming {
    top: -4px;
}
.react-datepicker__navigation:hover *::before {
    border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
    position: relative;
    top: -1px;
    font-size: 20px;
    width: 0;
}
.react-datepicker__navigation-icon--next {
    left: -2px;
}
.react-datepicker__navigation-icon--next::before {
    transform: rotate(45deg);
    left: -7px;
}
.react-datepicker__navigation-icon--previous {
    right: -2px;
}
.react-datepicker__navigation-icon--previous::before {
    transform: rotate(225deg);
    right: -7px;
}

.react-datepicker__month-container {
    float: left;
}

.react-datepicker__year {
    margin: 0.4rem;
    text-align: center;
}
.react-datepicker__year-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 180px;
}
.react-datepicker__year .react-datepicker__year-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
}

.react-datepicker__month {
    margin: 25px;
    text-align: center;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
}

.react-datepicker__input-time-container {
    clear: both;
    width: 100%;
    float: left;
    margin: 5px 0 10px 15px;
    text-align: left;
}
.react-datepicker__input-time-container .react-datepicker-time__caption {
    display: inline-block;
}
.react-datepicker__input-time-container
    .react-datepicker-time__input-container {
    display: inline-block;
}
.react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__input {
    display: inline-block;
    margin-left: 10px;
}
.react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__input
    input {
    width: auto;
}
.react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__input
    input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__input
    input[type="time"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__input
    input[type="time"] {
    -moz-appearance: textfield;
    appearance: textfield;
}
.react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__delimiter {
    margin-left: 5px;
    display: inline-block;
}

.react-datepicker__time-container {
    float: right;
    border-left: 1px solid #aeaeae;
    width: 85px;
}
.react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -87px;
    top: 0;
}
.react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white;
    border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 85px;
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center;
    border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    list-style: none;
    margin: 0;
    height: calc(195px + (1.7rem / 2));
    overflow-y: scroll;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    box-sizing: content-box;
}
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: 30px;
    padding: 5px 10px;
    white-space: nowrap;
}
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    background-color: #f0f0f0;
}
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: #216ba5;
    color: white;
    font-weight: bold;
}
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover {
    background-color: #216ba5;
}
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled {
    color: #ccc;
}
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled:hover {
    cursor: default;
    background-color: transparent;
}

.react-datepicker__week-number {
    color: #ccc;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
    white-space: nowrap;
}

.react-datepicker__day-names {
    margin-bottom: -8px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #000;
    display: inline-block;
    width: 25px;
    line-height: 1.7rem;
    text-align: center;
    margin: 8px;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
    cursor: pointer;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
    font-weight: bold;
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
    border-radius: 0.3rem;
    background-color: #3dcc4a;
    color: #fff;
}
.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
    background-color: #32be3f;
}
.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
    color: magenta;
}
.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
    color: green;
}
.react-datepicker__day--holidays,
.react-datepicker__month-text--holidays,
.react-datepicker__quarter-text--holidays,
.react-datepicker__year-text--holidays {
    position: relative;
    border-radius: 0.3rem;
    background-color: #ff6803;
    color: #fff;
}
.react-datepicker__day--holidays .holiday-overlay,
.react-datepicker__month-text--holidays .holiday-overlay,
.react-datepicker__quarter-text--holidays .holiday-overlay,
.react-datepicker__year-text--holidays .holiday-overlay {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 4px;
    border-radius: 4px;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s ease-in-out;
}
.react-datepicker__day--holidays:hover,
.react-datepicker__month-text--holidays:hover,
.react-datepicker__quarter-text--holidays:hover,
.react-datepicker__year-text--holidays:hover {
    background-color: #cf5300;
}
.react-datepicker__day--holidays:hover .holiday-overlay,
.react-datepicker__month-text--holidays:hover .holiday-overlay,
.react-datepicker__quarter-text--holidays:hover .holiday-overlay,
.react-datepicker__year-text--holidays:hover .holiday-overlay {
    visibility: visible;
    opacity: 1;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    background-color: #1d5d90;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #bad9f1;
    color: rgb(0, 0, 0);
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
    background-color: #1d5d90;
}
.react-datepicker__day--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ),
.react-datepicker__month-text--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ),
.react-datepicker__quarter-text--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ),
.react-datepicker__year-text--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ) {
    background-color: rgba(33, 107, 165, 0.5);
}
.react-datepicker__month--selecting-range
    .react-datepicker__day--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
    ),
.react-datepicker__year--selecting-range
    .react-datepicker__day--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
    ),
.react-datepicker__month--selecting-range
    .react-datepicker__month-text--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
    ),
.react-datepicker__year--selecting-range
    .react-datepicker__month-text--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
    ),
.react-datepicker__month--selecting-range
    .react-datepicker__quarter-text--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
    ),
.react-datepicker__year--selecting-range
    .react-datepicker__quarter-text--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
    ),
.react-datepicker__month--selecting-range
    .react-datepicker__year-text--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
    ),
.react-datepicker__year--selecting-range
    .react-datepicker__year-text--in-range:not(
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__year-text--in-selecting-range
    ) {
    background-color: #f0f0f0;
    color: #000;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
    cursor: default;
    color: #ccc;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
    background-color: transparent;
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
    position: absolute;
    padding: 0.5rem;
}

.react-datepicker__view-calendar-icon input {
    padding: 6px 10px 5px 25px;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
    border: 1px solid transparent;
    border-radius: 0.3rem;
    position: relative;
}
.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
    cursor: pointer;
}
.react-datepicker__year-read-view:hover
    .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover
    .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover
    .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover
    .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover
    .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover
    .react-datepicker__month-read-view--down-arrow {
    border-top-color: #b3b3b3;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    transform: rotate(135deg);
    right: -16px;
    top: 0;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
    background-color: #f0f0f0;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: 0.3rem;
    border: 1px solid #aeaeae;
}
.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
    cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}
.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
    background-color: #ccc;
}
.react-datepicker__year-option:hover
    .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover
    .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover
    .react-datepicker__navigation--years-upcoming {
    border-bottom-color: #b3b3b3;
}
.react-datepicker__year-option:hover
    .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover
    .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover
    .react-datepicker__navigation--years-previous {
    border-top-color: #b3b3b3;
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px;
}

.react-datepicker__close-icon {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0 6px 0 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
}
.react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #216ba5;
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "×";
}

.react-datepicker__today-button {
    background: #f0f0f0;
    border-top: 1px solid #aeaeae;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
}

.react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
}
@media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
        width: 2rem;
        line-height: 2rem;
    }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.44rem;
}

.react-datepicker__children-container {
    width: 13.8rem;
    margin: 0.4rem;
    padding-right: 0.2rem;
    padding-left: 0.2rem;
    height: auto;
}

.react-datepicker__aria-live {
    position: absolute;
    clip-path: circle(0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
    white-space: nowrap;
}

.react-datepicker__calendar-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.125em;
}

/* FINAL CALENDARI */

.acumuladomoneda {
    font-size: 26px;
    font-weight: 600;
    display: inline-block;
}

.m15_1_1_1 {
    margin: 15px 1px 1px 1px;
}

.radiotarifa {
    font-size: 20px;
    width: 100%;
}

.mt-0 {
    margin-top: 0;
}

.w70 {
    width: 70px;
}

.font20 {
    font-size: 20px;
}

.fw600 {
    font-weight: 600;
}

.f13 {
    font-size: 13px;
}

.f16 {
    font-size: 16px;
}

.pl-6 {
    padding-left: 6px;
}

.cff000 {
    color: #ff0000;
}

.mt-3 {
    margin-top: 3px;
}

.w180 {
    width: 180px;
}

.mt--2 {
    margin-top: -2px;
}

.buscador-inputs {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 5px 5px;
}

.calendari-fitxa-propietat {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
}

.titolmes {
    margin-top: 15px;
    margin-bottom: 15px;
}

.diainvalid {
    cursor: not-allowed;
}

.background_black {
    background-color: #000;
}

.nav-pills .nav-link.active {
    background-color: var(--corporatiu);
    border-radius: 10px 10px 0px 0px;
}

.nav-link:focus,
.nav-link:hover {
    color: var(--corporatiu);
}

.nav-link {
    color: #000;
}

.pillsfaqs,
.navleft {
    border-radius: 15px;
}

.imatge-reserva {
    border-radius: 15px;
    max-height: 580px;
}

.imatge-reserva-pas2 {
    border-radius: 15px;
    max-height: 100px;
}

.cursor-pointer {
    cursor: pointer;
}

#rfs-btn {
    border: none;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
    box-shadow: 10px 10px 10px 10px rgba(186, 186, 186, 0.25);
    margin-top: 29px !important;
    max-height: 400px !important;
    border: none !important;
    border-radius: 5px !important;
}

.ReactFlagsSelect-module_selectFlag__2q5gC {
    display: none !important;
}

.w100 {
    width: 100%;
}

.mw201 {
    min-width: 201px;
}

/* CUSTOM SELECT */

.custom-select-container {
    display: inline-block;
    min-width: 201px;
    text-align: center;
    position: relative;
    cursor: pointer;
}

.selected-text {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #eee;
    border-radius: 5px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-align: left;
}

.selected-text::after {
    content: "";
    position: absolute;
    right: 10px;
    top: 16px;
    border: 7px solid transparent;
    border-color: #000 transparent transparent transparent;
}

.selected-text.active::after {
    top: 8px;
    border-color: transparent transparent #000 transparent;
}

ul.select-options {
    margin: 0;
    padding: 0;
    text-align: center;
    z-index: 100;
}

.select-options {
    position: absolute;
    width: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;
    max-height: 16em;
    border-radius: 15px;
    box-shadow: 10px 10px 10px 10px rgba(186, 186, 186, 0.25);
}

li.custom-select-option {
    list-style-type: none;
    padding: 6px 20px;
    background: #fff;
    cursor: pointer;
    text-align: left;
}

li.custom-select-option:hover {
    background-color: #eee;
    color: #000;
}

.accordion-button:not(.collapsed) {
    background-color: #e6e6e6;
    border-color: #e6e6e6;
    color: #000;
    box-shadow: none;
}

.accordion-button:focus {
    border-color: #e6e6e6;
    box-shadow: none;
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.accordion-item:first-of-type .accordion-button {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.nav-tabs {
    --bs-nav-tabs-border-width: 1px;
}

.numpersonas,
.cpointer {
    cursor: pointer;
}

.mt25 {
    margin-top: 25px;
}

/* ********** */

/* Slideshow container */
.carrusel-container {
    position: relative;
    margin: 50px auto 20px auto;
    max-width: 600px;
    max-height: 700px;
}

/* Style each slide */
.comentario {
    display: none;
    width: 100%;
    height: 100%;
}

/* Next & previous buttons */
.prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    background: rgba(0, 0, 0, 0.3);
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border: none;
}

/* Position the "next" button to the right */
.next {
    right: 0;
}

/* On hover, add a semi-transparent black background */
.prev:hover,
.next:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.titulo {
    width: 100%;
    max-height: 300px;
    padding: 60px 12px 10px 12px;
    text-align: center;
}

.texto,
.persona,
.estrellas {
    text-align: center;
    padding-bottom: 10px;
}

.texto {
    padding-left: 100px;
    padding-right: 100px;
}

.solomovil {
    display: none;
}

@media (max-width: 734px) {
    .texto {
        padding-left: 50px;
        padding-right: 50px;
    }
    .prev,
    .next {
        padding: 8px;
    }

    .solomovil {
        display: block !important;
        background: none;
        border: 0;
        border-radius: 0;
        box-shadow: none;
    }
}

/* The dots indicator */
.dots {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 5px;
    background-color: #ccc;
    border-radius: 50%;
    display: inline-block;
    transition: 0.5s;
}

.activar,
.dot:hover {
    background-color: gray;
}

/* Fading animation */
.fader {
    animation-name: fade;
    animation-duration: 1.5s;
    height: 100%;
    border: 1px solid #1111;
    border-radius: 15px;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
}

@keyframes fader {
    from {
        opacity: 0.3;
    }
    to {
        opacity: 1;
    }
}

@media (min-width: 738px) and (max-width: 1024px) {
    .ReactFlagsSelect-module_selectValue__152eS {
        font-size: 25px;
        font-weight: 300;
        color: #000;
    }
}

.react-datepicker {
    z-index: 1002;
}

@media (max-width: 734px) {
    .react-datepicker {
        width: 300px;
        margin-left: -20px;
    }

    .react-datepicker__month-container {
        width: 300px;
    }

    .react-datepicker__month {
        margin: 0;
    }
}

.destacats-desti {
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
}

.destacats-imatge-desti {
    width: 300px;
    border-radius: 15px 15px;
    max-height: 200px;
}

.destacats-imatge-desti3 {
    width: 600px;
    border-radius: 15px 15px;
    height: 410px;
}

.destacats-imatge-desti5 {
    /* width: 600px; */
    width: 480px;
    border-radius: 15px 15px;
    height: 320px;
}

.destacats-desti img:hover,
.destacats-imatge-desti3 img:hover {
    filter: brightness(50%);
}

@media (max-width: 734px) {
    .destacats-imatge-desti3 {
        width: 300px;
        border-radius: 15px 15px;
        max-height: 200px;
    }
}

.caixa-destins {
    display: inline-block;
    text-align: center;
    position: relative;
}

.sobreimagen {
    position: absolute;
    top: 50%;
    left: 50%;
    text-transform: uppercase;
    z-index: 100;
    transform: translate(-50%, -50%);
    color: #fff;
    font-weight: 600;
    text-shadow: 1px 2px 4px #000;
}

@media (min-width: 735px) {
    .ml_50 {
        margin-left: -50px;
    }
}

@media (max-width: 734px) {
    .ml_50 {
        margin-top: -50px;
    }
}

.zindexnegatiu {
    z-index: -1001;
}

.caracteristica {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 1rem;
    width: 4rem;
    font-size: 2rem;
}
.br15 {
    border-radius: 15px !important;
}

.nopadding {
    padding: 0;
}

.fitxaimagegran {
    max-height: calc(100vh - 80px);
    width: 100%;
    object-fit: contain;
}

.paddingtitulos {
    padding-top: 15px;
    padding-bottom: 20px;
}

.flex-dColumn {
    flex-direction: column;
}

.desti-grid3,
.desti-grid4,
.desti-grid5,
.desti-grid6 {
    display: grid;
    gap: 25px;
}

.desti-grid4 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
}
.desti-grid6 {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
}
.desti-grid2 {
    display: flex;
    gap: 25px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.desti-grid3 {
    grid-template-columns: auto;
    grid-template-rows: repeat(2, 1fr);
}
.desti-grid3 :nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
}
.desti-grid3 :nth-child(2) {
    grid-area: 2 / 1 / 3 / 2;
}
.desti-grid3 :nth-child(3) {
    grid-area: 1 / 2 / 3 / 3;
}

.desti-grid5 {
    grid-template-columns: auto;
    grid-template-rows: auto;
}
.desti-grid5 :nth-child(1) {
    grid-area: 1 / 1 / 3 / 2;
    align-content: center;
}
.desti-grid5 :nth-child(2) {
    grid-area: 3 / 1 / 5 / 2;
    align-content: center;
}
.desti-grid5 :nth-child(3) {
    grid-area: 5 / 1 / 7 / 2;
    align-content: center;
}
.desti-grid5 :nth-child(4) {
    grid-area: 1 / 2 / 4 / 3;
    align-content: center;
}
.desti-grid5 :nth-child(5) {
    grid-area: 4 / 2 / 7 / 3;
    align-content: center;
}

@media (max-width: 1070px) {
    .desti-grid3 {
        grid-template-rows: auto;
    }
    .desti-grid3 :nth-child(1) {
        grid-area: 1 / 1 / 2 / 2;
    }
    .desti-grid3 :nth-child(2) {
        grid-area: 1 / 2 / 2 / 3;
    }
    .desti-grid3 :nth-child(3) {
        grid-area: 2 / 1 / 3 / 3;
    }
    .destacats-imatge-desti3,
    .destacats-imatge-desti5 {
        width: 410px;
        height: 270px;
    }
    .desti-grid6 {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
    }
}

@media (max-width: 860px) {
    .destacats-imatge-desti5 {
        width: 300px;
        max-height: 200px;
    }
    .desti-grid5 :nth-child(4) {
        margin-top: 100px;
    }
    .desti-grid5 :nth-child(5) {
        margin-bottom: 100px;
    }
}
@media (max-width: 734px) {
    .destacats-imatge-desti3,
    .destacats-imatge-desti5 {
        width: 300px;
        max-height: 200px;
    }
    .desti-grid3,
    .desti-grid4,
    .desti-grid5,
    .desti-grid6 {
        display: flex;
        flex-direction: column;
    }
    .desti-grid5 :nth-child(4) {
        margin-top: 0px;
    }
    .desti-grid5 :nth-child(5) {
        margin-bottom: 0px;
    }
}

.text-nowrap {
    white-space: nowrap;
}

/* **** */

.dropdown-submenu-container {
    position: relative;
}
.dropdown-submenu-container a.dropdown-submenu::after {
    transform: rotate(-90deg);
    position: absolute;
    right: 6px;
    top: 0.8em;
}

.dropdown-menu-right .dropdown-submenu-container a.dropdown-submenu::after {
    transform: rotate(90deg);
    position: absolute;
    left: 0px;
    right: unset;
    top: 0.8em;
}

.dropdown-menu-end .dropdown-submenu-container a.dropdown-submenu::after {
    transform: rotate(90deg);
    position: absolute;
    left: 0px;
    right: unset;
    top: 0.8em;
}

.dropdown-submenu-container .dropdown-menu {
    top: 0;
    left: 100%;
    margin-left: 1px;
    margin-right: auto;
}
.dropdown-menu-right .dropdown-submenu-container .dropdown-menu {
    left: auto;
    right: 100%;
    margin-left: auto;
    margin-right: 1px;
}

.dropdown-menu-end .dropdown-submenu-container .dropdown-menu {
    left: auto;
    right: 100%;
    margin-left: auto;
    margin-right: 1px;
}

/* **** */

.mb-6 {
    margin-bottom: 5rem !important;
}

.solofotomobile {
    display: none;
}

.solofotopc {
    display: block;
}

@media (max-width: 734px) {
    .solofotomobile {
        display: block;
    }

    .solofotopc {
        display: none;
    }

    .logobl1 {
        width: 250px;
    }
}

/* *********** */

.floating-button {
    position: fixed;
    bottom: 100px;
    right: 20px;
    background-color: #25d366 !important; /* Color de WhatsApp */
    color: white !important;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    font-size: 24px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    z-index: 1000;
}

.floating-button:hover {
    background-color: #128c7e; /* Color más oscuro al pasar el mouse */
}
